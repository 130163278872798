import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SpinnerComponent } from 'src/app/components/spinner/spinner.component';
import { BranchesComponent } from './components/branches/branches.component';

export * from './models/index';
export * from './services/branch.service';

@NgModule( {
  declarations: [
    BranchesComponent
  ],
  imports: [
    CommonModule,
    SpinnerComponent,
    RouterModule.forChild([
      { path: '', component: BranchesComponent }
    ])
  ],
  exports: [
    RouterModule
  ]
})
export class BranchModule { }
