import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IBookingServiceRequest } from "src/app/modules/bookings/bookings.module";
import { IService } from "src/app/modules/services/services.module";
import { IStaff } from "../../models";

@Component({
  selector: 'app-staff-service',
  templateUrl: './staff-service.component.html'
})
export class StaffServiceComponent {

  @Input('availableStaff') availableStaff!: IStaff[];
  @Input('booking') booking!: IBookingServiceRequest;
  @Input('service') service!: IService;

  @Output('changeBooking') emitChangeBooking = new EventEmitter<IBookingServiceRequest>();

  onChangeBooking() {
    this.emitChangeBooking.emit(this.booking);
  }

}
