<div class="row">
  <div class="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">

    <h2 class="mb-4 text-center">Log in</h2>

    <app-spinner *ngIf="loading"></app-spinner>

    <div class="card" *ngIf="!loading">
      <div class="card-body">

        <p class="text-center">We'll send a one time code to your email to log in with.</p>

        <form #loginForm="ngForm" (ngSubmit)="onSubmit()">

          <div class="form-floating mb-3">
            <input id="emailAddress"
                  name="emailAddress"
                  type="email"
                  class="form-control"
                  [(ngModel)]="model.emailAddress"
                  #emailAddress="ngModel"
                  placeholder="Email address"
                  email
                  required
                  maxlength="320">
            <label for="emailAddress" class="form-label">Enter your email address</label>
            <div [hidden]="emailAddress.valid || emailAddress.pristine" class="text-danger">
              <div *ngIf="emailAddress.errors?.email">Must be a valid email address.</div>
              <div *ngIf="emailAddress.errors?.maxlength">Cannot be more than 320 characters.</div>
              <div *ngIf="emailAddress.errors?.required">Email address is required.</div>
            </div>
          </div>

          <div class="form-check mb-3">
            <input class="form-check-input" type="checkbox" id="rememberMe" name="rememberMe" [(ngModel)]="model.rememberMe">
            <label class="form-check-label" for="rememberMe">
              Remember me on this device for {{ rememberDays }} days
            </label>
          </div>

          <div class="d-grid gap-2">
            <button type="submit" class="btn btn-primary" [disabled]="loginForm.invalid">
              Send code
            </button>

            <button type="button" class="btn btn-secondary" (click)="continueAsGuest()">
              Continue as guest
            </button>
          </div>

        </form>

      </div>
    </div>

  </div>
</div>