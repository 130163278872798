<div class="row">
  <div class="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">

    <h2 class="mb-4 text-center">Log in</h2>

    <app-spinner *ngIf="loading"></app-spinner>

    <div class="card" *ngIf="!loading">
      <div class="card-body">

        <p>We've sent an email to {{ (authPending$ | async)?.emailAddress }} with a code to enter below.</p>

        <form #loginForm="ngForm" (ngSubmit)="onSubmit()">
          <div class="form-floating mb-3">
            <input id="loginCode" name="loginCode"
                [(ngModel)]="model.loginCode"
                #loginCode="ngModel"
                class="form-control form-control-lg"
                type="tel"
                pattern="[0-9]*"
                minlength="6"
                maxlength="6"
                placeholder="Code"
                required>
            <label for="loginCode">Enter code from email</label>
            <div [hidden]="loginCode.valid || loginCode.pristine" class="text-danger">
              <div *ngIf="loginCode.errors?.required">Code is required.</div>
            </div>
          </div>

          <div class="d-grid gap-2">
            <button type="submit" class="btn btn-primary" [disabled]="loginForm.invalid">Log in</button>
            <button type="button" class="btn btn-outline-secondary" (click)="resendCode()">Resend code</button>
          </div>

        </form>

      </div>
    </div>

  </div>
</div>
