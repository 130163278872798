import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { AppSettingsService } from 'src/app/modules/app-settings/app-settings.module';
import { AuthService } from '../../auth.module';

@Component({
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit, AfterViewInit {

  constructor(
    private _appService: AppService,
    private _appSettings: AppSettingsService,
    private _authService: AuthService,
    private _logger: NGXLogger,
    private _route: ActivatedRoute,
    private _router: Router
  ) { }

  private readonly _isValid$ = new BehaviorSubject<boolean>(false);

  public loading = true;
  public model = {
    emailAddress: '',
    rememberMe: false
  }
  public rememberDays: number = 0;

  @ViewChild('loginForm') loginForm: any;

  private _bindForm() {
    this.loginForm.statusChanges
      .subscribe((status: string) => {
        this._isValid$.next(status === 'VALID');
      });
  }

  private _loadAuthData() {
    return this._authService.authPending$
      .pipe(
        take(1),
        tap(data => {
          if (!!data) {
            this.model.emailAddress = data.emailAddress;
            this.model.rememberMe = data.rememberMe;
          }
        })
      );
  }

  private _loadSettings() {
    return this._appSettings.appSettings$
      .pipe(
        take(1),
        tap(settings => {
          if (!!settings) {
            this.rememberDays = settings.loginRememberDays;
          }
        })
      );
  }

  ngOnInit() {
    this._appService.clearError();
    this._appService.setStep('login');

    forkJoin([
      this._loadAuthData(),
      this._loadSettings()
    ]).subscribe(_ => {
      this.loading = false;
    });
  }

  ngAfterViewInit() {
    this._bindForm();
  }

  continueAsGuest() {
    this._appService.goToNextUncompletedStep();
  }

  onSubmit() {
    if (!this._isValid$.getValue()) { return; }

    this._logger.trace('LoginComponent | onSubmit');

    this.loading = true;
    this._appService.clearError();

    this._authService.startLoginAsync(this.model.emailAddress, this.model.rememberMe)
      .pipe(take(1))
      .subscribe(result => {
        this._logger.trace(`LoginComponent | onSubmit`, result);
        this._router.navigate(['./code'], { relativeTo: this._route });
      }, (errorResponse: HttpErrorResponse) => {
        if (!!errorResponse.error) {
          this._appService.setError(errorResponse.error);
        }
        else
        {
          this._logger.error(`LoginComponent | onSubmit`, errorResponse);
          this._appService.setError('There was an unknown error processing your request.');
        }

        this.loading = false;
        return;
      });

  }

}
