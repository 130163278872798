import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';

@Injectable({ providedIn: 'root' })
export class ServicesGuard  {

  constructor (
    private _appService: AppService,
    private _router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
  {
    return this._appService.branch$
      .pipe(
        take(1),
        map(branch =>  {
          if (!!branch) { return true; }
          return this._router.parseUrl(route.parent!.parent!.url[0].path);
        })
      );
  }

}
