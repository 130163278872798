import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SpinnerComponent } from 'src/app/components/spinner/spinner.component';
import { StaffPackageComponent } from './components/package/staff-package.component';
import { ServiceStaffComponent } from './components/service-staff/service-staff.component';
import { StaffServiceComponent } from './components/service/staff-service.component';
import { StaffComponent } from './components/staff/staff.component';

export * from './models/index';
export * from './route-guards/index';
export * from './services/staff.service';

@NgModule({
  declarations: [
    ServiceStaffComponent,
    StaffComponent,
    StaffPackageComponent,
    StaffServiceComponent
  ],
  imports: [
    CommonModule,
    SpinnerComponent,
    RouterModule.forChild([
      { path: '', component: StaffComponent }
    ])
  ],
  exports: [
    RouterModule
  ]
})
export class StaffModule { }
