export enum PaymentRequirement {
  none = 0,
  full = 1,
  depositPercent = 2,
}

export interface IBusiness {
  cancellationPolicy: string;
  depositAmount?: number;
  hasLogo: boolean;
  onlinePencilOnly: boolean;
  paymentRequirement: PaymentRequirement;
  showPrices: boolean;
  staffTaxonomyPlural: string;
  staffTaxonomySingular: string;
  theme: string;
  tradingName: string;
}
