import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ICustomer } from '../../customers/customers.module';
import { ILoginSuccessResponse } from '../models';
import { StateService } from 'src/app/services/state.service';

export interface IAuthPending {
  emailAddress: string;
  rememberMe: boolean;
}

@Injectable({ providedIn: 'root' })
export class AuthService {

  private _authPending$ = new BehaviorSubject<IAuthPending | null>(null);
  private _customer$ = new BehaviorSubject<ICustomer | null>(null);

  public authPending$ = this._authPending$.asObservable();
  public customer$ = this._customer$.asObservable();

  constructor(
    private _http: HttpClient,
    private _logger: NGXLogger,
    private _stateService: StateService
  ) {
    this._loadData();
  }

  private _completeLogin(response: ILoginSuccessResponse): ICustomer {
    this._logger.trace('AuthService | Complete Login', response);

    localStorage.setItem(this._getAuthTokenKey(), response.jwt);

    const customer: ICustomer = {
      customerRef: response.customerRef,
      emailAddress: response.emailAddress,
      emailPromotions: response.emailPromotions,
      firstName: response.firstName,
      fullName: response.fullName,
      lastName: response.lastName,
      mobileNumber: response.mobileNumber,
      preferredName: response.preferredName
    };

    this.updateCustomer(customer);

    return customer;
  }

  private _clearAuthPending() {
    this._logger.trace('AuthService | ClearAuthPending');
    localStorage.removeItem(this._getAuthPendingKey());
    this._authPending$.next(null);
  }

  private _getAuthPendingKey(): string {
    return `esalon.${this._stateService.state.subscriptionSlug}.auth.pending`;
  }

  private _getAuthTokenKey(): string {
    return `esalon.${this._stateService.state.subscriptionSlug}.auth.token`;
  }

  private _getUserKey(): string {
    return `esalon.${this._stateService.state.subscriptionSlug}.user`;
  }

  private _loadData(): void {
    const authPendingData = localStorage.getItem(this._getAuthPendingKey());
    if (!!authPendingData) {
      this._authPending$.next(JSON.parse(authPendingData));
      this._logger.trace('AuthService | LoadData | authPending', authPendingData);
    }

    const user = localStorage.getItem(this._getUserKey());
    this._logger.trace('AuthService | LoadData | user', user);
    if (!!user) {
      this._customer$.next(JSON.parse(user));
    } else {
      this._customer$.next(null);
    }
  }

  public getAuthToken(): string | null {
    return localStorage.getItem(this._getAuthTokenKey());
  }

  public logout(): void {
    this._logger.trace('AuthService | Logout');
    this._clearAuthPending();
    localStorage.removeItem(this._getAuthTokenKey());
    localStorage.removeItem(this._getUserKey());
    this._customer$.next(null);
  }

  public reloadCustomer() {
    this._loadData();
  }

  public setAuthPending(emailAddress: string, rememberMe: boolean) {
    this._logger.trace('AuthService | setAuthPending', emailAddress, rememberMe);
    const authPending: IAuthPending = {
      emailAddress: emailAddress,
      rememberMe: rememberMe
    };

    localStorage.setItem(this._getAuthPendingKey(), JSON.stringify(authPending));
    this._authPending$.next(authPending);
  }

  public startLoginAsync(emailAddress: string, rememberMe: boolean): Observable<void> {
    return this._http.post<void>('auth/login/start', { emailAddress, rememberMe })
      .pipe(
        tap(_ => this.setAuthPending(emailAddress, rememberMe))
      );
  }

  public updateCustomer(customer: ICustomer) {
    if (customer.preferredName.length > 0) {
      customer.fullName = `${customer.preferredName} ${customer.lastName}`.trim();
    }
    else {
      customer.fullName = `${customer.firstName} ${customer.lastName}`.trim();
    }
    localStorage.setItem(this._getUserKey(), JSON.stringify(customer));
    this._customer$.next(customer);
  }

  public verifyCodeAsync(code: string): Observable<ICustomer> {
    const authPending = this._authPending$.getValue();

    return this._http.post<ILoginSuccessResponse>('auth/login/verify', {
      emailAddress: authPending?.emailAddress,
      rememberMe: authPending?.rememberMe,
      totp: code
    })
    .pipe(
      tap(_ => { this._clearAuthPending();}),
      map(response => this._completeLogin(response))
    );
  }
}
