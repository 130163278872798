<div class="d-flex align-items-center" (click)="onClick()" [ngClass]="{'cursor-pointer': hasMultipleStaffAvailable}">
  <div>
    <h6 *ngIf="!!serviceName" class="fw-bold mb-0">{{serviceName}}</h6>
    <span *ngIf="!staffPreferred && hasMultipleStaffAvailable">With anyone available</span>
    <span *ngIf="staffPreferred">With <strong>{{getBookingStaffName()}}</strong></span>
    <span *ngIf="staffPreferred && !hasMultipleStaffAvailable"> (only {{getBookingStaffName()}} can provide this service)</span>
  </div>
  <div *ngIf="hasMultipleStaffAvailable" class="ms-auto">
    <i class="fs-4 bi bi-chevron-right"></i>
  </div>
</div>
