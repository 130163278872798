<h2 class="mb-3">Select a branch</h2>

<app-spinner *ngIf="loading"></app-spinner>

<div *ngIf="!loading">

  <div class="d-grid gap-3">
    <div class="card cursor-pointer"
        *ngFor="let branch of branches"
        [ngClass]="{'border-primary': (selectedBranchId$ | async) === branch.id}"
        (click)="onBranchChange(branch)">
      <div class="card-body">
        <div class="fw-bold">{{branch.name}}</div>
        <div *ngIf="!!branch.address && branch.address.length" class="text-muted"> {{ branch.address}}</div>
      </div>
    </div>
  </div>

  <div class="d-grid d-md-block">
    <button class="btn btn-primary mt-3" type="button" (click)="onContinue()" [disabled]="!(isValid$ | async)">
      Continue
    </button>
  </div>
</div>
