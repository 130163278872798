import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { AuthService } from '../../../services/auth.service';

@Component({
  templateUrl: './login-code.component.html',
})
export class LoginCodeComponent implements OnInit {

  constructor(
    private _appService: AppService,
    private _authService: AuthService,
    private _route: ActivatedRoute,
    private _router: Router
  ) { }

  public authPending$ = this._authService.authPending$;
  public loading = false;
  public model = {
    loginCode: ''
  };

  private checkCodeFromQueryString(): boolean {
    const emailAddress = this._route.snapshot.queryParams['email']?.trim();
    const rememberMe: boolean = this._route.snapshot.queryParams['rememberMe']?.trim().toLowerCase() === 'true';
    const code = this._route.snapshot.queryParams['code']?.trim();

    if (!!emailAddress && !!code) {
      this._authService.setAuthPending(emailAddress, rememberMe);
      this.model.loginCode = code;
      return true;
    }

    return false;
  }

  ngOnInit() {
    if (this.checkCodeFromQueryString()) {
      this.onSubmit();
    }
    else {
      this._appService.setStep('loginCode');
    }
  }

  onSubmit() {
    this.loading = true;
    this._appService.clearError();

    this._authService.verifyCodeAsync(this.model.loginCode)
      .pipe(take(1))
      .subscribe(user => {
        this._appService.setCustomer(user);
        this._appService.goToNextUncompletedStep();
      }, error => {
        if (!!error.status && error.status === 401) {
          this._appService.setError({ title: 'The provided code is not valid.' });
        }
        else
        {
          this._appService.setError(error);
        }
        this.loading = false;
      });
  }

  resendCode() {
    this._router.navigate(['../'], { relativeTo: this._route });
  }
}
