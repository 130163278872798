import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { delay, map, take } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { BranchService, IBranch } from '../../branch.module';

@Component({
  templateUrl: './branches.component.html'
})
export class BranchesComponent implements OnInit {

  constructor(
    private _appService: AppService,
    private _branchService: BranchService,
  ) { }

  private readonly _isValid$ = new BehaviorSubject<boolean>(false);

  public branches: IBranch[] = [];
  public loading = true;
  public readonly isValid$ = this._isValid$.asObservable();
  public readonly selectedBranchId$ = this._appService.branch$.pipe(map(b => b?.id));

  private _bindSelectedBranchId() {
    this.selectedBranchId$
    .pipe(
      take(1),
      delay(0)
    )
    .subscribe(id => {
      if (!!id) {
        this._isValid$.next(id > 0);
      }
    });
  }

  private _getBranches() {
    this._branchService.getForCurrentSubscription()
      .pipe(take(1))
      .subscribe(branches => {
        this.branches = branches;

        this._selectFirstBranch();

        this.loading = false;
      });
  }

  private _selectFirstBranch() {
    if (!this.branches.length) { return; }

    this.selectedBranchId$
      .pipe(take(1))
      .subscribe(branchId => {
        if (!!branchId && branchId > 0) { return; }

        const branch = this.branches[0];
        this.onBranchChange(branch);
      });
  }

  ngOnInit() {
    this._appService.setStep('branches');
    this._bindSelectedBranchId();
    this._getBranches();
  }

  onBranchChange(branch: IBranch) {
    this._appService.setBranch(branch)
      .pipe(take(1))
      .subscribe(() => {
        this._isValid$.next(true);
      });
  }

  onContinue() {
    this._appService.clearError();
    this._appService.goToNextStep();
  }

}
