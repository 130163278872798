import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IAppSettings } from '../models';

@Injectable({ providedIn: 'root' })
export class AppSettingsService {

  constructor(
    private _http: HttpClient,
  ) {}

  private readonly _appSettings$ = new BehaviorSubject<IAppSettings | null>(null);

  public readonly appSettings$ = this._appSettings$.asObservable();

  public getSettings(): Observable<IAppSettings> {
    return this._http.get<IAppSettings>('settings')
      .pipe(
        tap(settings => this._appSettings$.next(settings))
      );
  }

}
