import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IBranch } from '../models';

@Injectable({ providedIn: 'root' })
export class BranchService {

  constructor(
    private _http: HttpClient
  ) { }

  private readonly _branches$ = new BehaviorSubject<IBranch[]>([]);

  public readonly branches$ = this._branches$.asObservable();

  public findByRef(branchRef: number): IBranch | undefined {
    return this._branches$.value.find(b => b.id === branchRef);
  }

  public getForCurrentSubscription(): Observable<IBranch[]> {
    return this._http.get<IBranch[]>('branches')
      .pipe(tap(branches => this._branches$.next(branches)));
  }
}
