import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IBusiness } from '../models';

@Injectable({ providedIn: 'root' })
export class BusinessService {

  constructor(
    private _http: HttpClient,
  ) {}

  private readonly _business$ = new BehaviorSubject<IBusiness | null>(null);

  public readonly business$ = this._business$.asObservable();

  public getBusiness(): Observable<IBusiness> {
    return this._http.get<IBusiness>('business')
      .pipe(
        tap(business => this._business$.next(business))
      );
  }

  public getCancellationPolicy(): string | undefined {
    const business = this._business$.getValue();

    if (!business) { return undefined; }
    if (!business.cancellationPolicy) { return undefined; }
    if (!business.cancellationPolicy.length) { return undefined; }
    return business.cancellationPolicy;
  }

}
