import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IBookingServiceRequest } from 'src/app/modules/bookings/bookings.module';
import { StaffService } from '../../staff.module';

@Component({
  selector: 'app-service-staff',
  templateUrl: './service-staff.component.html'
})
export class ServiceStaffComponent implements OnInit {

  constructor(
    private _staffService: StaffService
  ) { }

  public hasMultipleStaffAvailable = false;
  public staffPreferred = false;

  @Input('booking') booking!: IBookingServiceRequest;
  @Input('serviceName') serviceName?: string;

  @Output('change') emitChange = new EventEmitter<IBookingServiceRequest>();

  ngOnInit() {
    this.staffPreferred = this.booking.staffPreferred;
    this.hasMultipleStaffAvailable = this._staffService.getStaffForService(this.booking.itemRef).length > 1;
  }

  getBookingStaffName(): string {
    return !!this.booking.staffRef
      ? this._staffService.findByRef(this.booking.staffRef).fullName
      : '';
  }

  onClick() {
    if (!this.hasMultipleStaffAvailable) { return; }
    this.emitChange.emit(this.booking);
  }

}
