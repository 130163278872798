import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SpinnerComponent } from 'src/app/components/spinner/spinner.component';
import { DurationPipe } from 'src/app/pipes/duration.pipe';
import { ServicesComponent } from './components/services/services.component';

export * from './models/index';
export * from './route-guards/index';
export * from './services/service.service';

@NgModule({
  declarations: [
    ServicesComponent
  ],
  imports: [
    CommonModule,
    DurationPipe,
    SpinnerComponent,
    RouterModule.forChild([
      { path: '', component: ServicesComponent }
    ])
  ],
  exports: [
    RouterModule
  ]
})
export class ServicesModule { }
