import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, tap } from 'rxjs/operators';
import { TIMEOUT_MINUTES } from './app.constants';
import { AuthService } from './modules/auth/auth.module';
import { BookingsService, IBooking, IBookingRequest, IBookingResponse, IBookingServiceRequest, IBookingSlot } from './modules/bookings/bookings.module';
import { BranchService, IBranch } from './modules/branches/branch.module';
import { BusinessService, IBusiness, PaymentRequirement } from './modules/businesses/business.module';
import { ICustomerDetails } from './modules/customer-details/models';
import { ICustomer } from './modules/customers/customers.module';
import { IService, ServiceService } from './modules/services/services.module';
import { IStaff, StaffService } from './modules/staff/staff.module';
import { ISubscription } from './modules/subscriptions/subscription.module';
import { StateService } from './services/state.service';

@Injectable({ providedIn: 'root' })
export class AppService {

  constructor(
    private _authService: AuthService,
    private _bookingsService: BookingsService,
    private _branchService: BranchService,
    private _businessService: BusinessService,
    private _logger: NGXLogger,
    private _recaptcha: ReCaptchaV3Service,
    private _route: ActivatedRoute,
    private _router: Router,
    private _serviceService: ServiceService,
    private _staffService: StaffService,
    private _stateService: StateService,
    private _titleService: Title
  ) {
    this._logger.trace('[AppService]');

    this._bindCustomer();
    this._bindSlot();
    this._bindSubscription();

    this._checkTimeout();
  }

  private readonly _actualServices$ = new BehaviorSubject<IService[]>([]);
  private readonly _bookingServiceRequests$ = new BehaviorSubject<IBookingServiceRequest[]>([]);
  private readonly _branch$ = new BehaviorSubject<IBranch | null>(null);
  private readonly _business$ = new BehaviorSubject<IBusiness | null>(null);
  private readonly _busy$ = new BehaviorSubject<boolean>(false);
  private readonly _canGoBack$ = new BehaviorSubject<boolean>(false);
  private readonly _error$ = new BehaviorSubject<any>(null);
  private readonly _selectedServicesAndPackages$ = new BehaviorSubject<IService[]>([]);
  private readonly _showSidebar$ = new BehaviorSubject<boolean>(true);
  private readonly _slot$ = new BehaviorSubject<IBookingSlot | undefined>(undefined);
  private readonly _slotEnds$ = new BehaviorSubject<Date | null>(null);
  private readonly _step$ = new BehaviorSubject<string>('subscription');
  private readonly _subscription$ = new BehaviorSubject<ISubscription | null>(null);
  private readonly _subscriptionSlug$ = new BehaviorSubject<string | null>(null);
  private readonly _userDetails$ = new BehaviorSubject<ICustomerDetails | null>(null);

  public readonly actualServices$ = this._actualServices$.asObservable();
  public readonly bookingServiceRequests$ = this._bookingServiceRequests$.asObservable();
  public readonly branch$ = this._branch$.asObservable();
  public readonly busy$ = this._busy$.asObservable();
  public readonly business$ = this._business$.asObservable();
  public readonly canGoBack$ = this._canGoBack$.asObservable();
  public readonly error$ = this._error$.asObservable();
  public readonly requiresPayment$ = this._businessService.business$.pipe(filter(b => !!b), map(b => b?.paymentRequirement !== PaymentRequirement.none));
  public readonly selectedServicesAndPackages$ = this._selectedServicesAndPackages$.asObservable();
  public readonly showSidebar$ = this._showSidebar$.asObservable();
  public readonly slot$ = this._slot$.asObservable();
  public readonly slotEnds$ = this._slotEnds$.asObservable();
  public readonly step$ = this._step$.asObservable();
  public readonly subscription$ = this._subscription$.asObservable();
  public readonly total$ = this._selectedServicesAndPackages$.pipe(
    map(services => services.map(s => s.salePriceInclTax).reduce((total, price) => total += price, 0))
  );
  public readonly userDetails$ = this._userDetails$.asObservable();

  public readonly paymentRequired$ = combineLatest([
    this.business$,
    this.total$
  ]).pipe(
    map(([business, total]) => {
      if (!business || business.paymentRequirement === PaymentRequirement.none) { return null; }
      if (total <= 0) { return null; }

      switch (business.paymentRequirement) {
        case PaymentRequirement.full:
          return total;

        case PaymentRequirement.depositPercent:
          return total * ((business.depositAmount ?? 0) / 100);
      }
    })
  );

  private _bindCustomer() {
    this._authService.customer$
      .subscribe(customer => {
        if (!!customer) {
          this.setCustomer(customer);
        }
      });
  }

  private _bindSlot() {
    this._slot$
      .subscribe(slot => {
        if (!slot) {
          this._slotEnds$.next(null);
          return;
        }

        const endsAt = new Date(slot.startTime);
        endsAt.setMinutes(endsAt.getMinutes() + this.getBookingDurationMinutes());
        this._slotEnds$.next(endsAt);
      });
  }

  private _bindSubscription() {
    this._subscription$
      .pipe(
        distinctUntilChanged()
      )
      .subscribe(subscription => {
        this._logger.debug('[AppService].[subscription$]', subscription);
        this._authService.reloadCustomer();
      });
  }

  private _loadBranches(): Observable<null> {
    this._logger.trace('AppService | loadBranches');

    return this._branchService.getForCurrentSubscription()
      .pipe(
        mergeMap(branches => {
          if (!branches.length) {
            this._logger.trace('AppService | loadBranches | no branches');
            return of(null);
          }

          if (this._stateService.state.branchRef !== undefined && this._stateService.state.branchRef > 0) {
            this._logger.trace('AppService | loadBranches | branchRef', this._stateService.state.branchRef);
            const branch = branches.find(b => b.id === this._stateService.state.branchRef);
            if (!!branch) {
              this._branch$.next(branch);
              return this._loadBranchData();
            }
          }

          if (branches.length === 1) {
            this._logger.trace('AppService | loadBranches | 1 branch');
            return this.setBranch(branches[0]);
          }

          return of(null);
        })
      );
  }

  private _loadBranchData(): Observable<null> {
    this._logger.trace('AppService | loadBranchData');

    return forkJoin([
      this._loadServices(),
      this._loadRosteredStaff()
    ]).pipe(
      map(_ => null)
    );
  }

  private _loadBusiness(): Observable<null> {
    this._logger.trace('AppService | loadBusiness');

    return this._businessService.getBusiness()
      .pipe(
        tap(business => this._business$.next(business)),
        mergeMap(business => {
          this._logger.trace('AppService | loadBusiness', business);

          this._titleService.setTitle(`Book now - ${business.tradingName}`);

          let theme = this._route.snapshot.queryParams.theme;
          if (!theme) { theme = business.theme; }
          this._setTheme(theme);

          return this._loadBranches();
        })
      );
  }

  private _loadRosteredStaff(): Observable<null> {
    this._logger.trace('AppService | loadRosteredStaff');

    return this._staffService.getRosteredStaff()
      .pipe(
        tap(staff => {
          this._logger.trace('AppService | loadRosteredStaff', staff);
        }),
        map(_ => null)
      );
  }

  private _loadSlot(): Observable<any> {
    this._logger.trace('AppService | loadSlot');

    if (!this._stateService.state.slot) {
      this._logger.trace('AppService | loadSlot | no slot');
      return of(null);
    }

    this._stateService.state.slot.startTime = new Date(this._stateService.state.slot.startTime);

    this._logger.trace('AppService | loadSlot', this._stateService.state.slot);
    this._slot$.next(this._stateService.state.slot);

    return this._loadUserDetails();
  }

  private _loadServices(): Observable<null> {
    this._logger.trace('AppService | loadServices');

    const branch = this._branch$.getValue();
    if (!branch) {
      this._logger.trace('AppService | loadServices | no branch');
      return of(null);
    }

    return this._serviceService.getServicesAndPackages(branch)
      .pipe(
        mergeMap(services => {
          if (!services || !services.length) {
            this._logger.trace('AppService | loadServices | no services');
            return of(null);
          }

          this._logger.trace('AppService | loadServices | serviceData', this._stateService.state.selectedServices);
          const selectedServices = this._stateService.state.selectedServices
            .map(selectedService => services.find(s => s.id === selectedService.id && s.isPackage == selectedService.isPackage))
            .filter((service): service is IService => !!service);

          this._selectedServicesAndPackages$.next(selectedServices);

          this._bookingServiceRequests$.next(this._stateService.state.bookedServices);

          return this._loadStaffForServices();
        })
      );
  }

  private _loadStaffForServices(): Observable<null> {
    this._logger.trace('AppService | loadStaffForServices');

    return this._staffService.getStaffForServices(this._bookingServiceRequests$.getValue().map(s => s.itemRef))
      .pipe(
        mergeMap(availableStaff => {
          this._logger.trace('AppService | loadStaffForServices', availableStaff);

          this._bookingServiceRequests$.next(this._stateService.state.bookedServices);

          return this._loadSlot();
        })
      );
  }

  private _loadUserDetails(): Observable<null> {
    this._logger.trace('AppService | loadUserDetails', this._stateService.state.userDetails);

    if (this._stateService.state.userDetails) {
      this._userDetails$.next(this._stateService.state.userDetails);
    }

    return of(null);
  }

  private _navigateTo(step: string) {
    const subscription = this._subscriptionSlug$.getValue();
    this._logger.trace('[AppService].[navigateTo]', step);
    this._router.navigateByUrl(`/${subscription}/${step}`);
  }

  private _publishBookedServices(bookedServices: IBookingServiceRequest[]) {
    this._logger.debug('[AppService].[publishBookedServices] services:', bookedServices);

    this._stateService.state.bookedServices = bookedServices;
    this._stateService.save();

    this._bookingServiceRequests$.next(bookedServices);
  }

  private _publishSelectedServices(selectedServices: IService[]) {
    this._stateService.state.selectedServices = selectedServices.map(s => {
      return {
        id: s.id,
        isPackage: s.isPackage
      }
    });
    this._stateService.save();

    this._selectedServicesAndPackages$.next(selectedServices);
  }

  private _setStaff() {
    this._logger.debug('[AppService].[setStaff] bookingServiceRequests:', this._bookingServiceRequests$.getValue());

    this._stateService.state.bookedServices = this._bookingServiceRequests$.getValue();
    this._stateService.save();

    this._bookingServiceRequests$.next(this._bookingServiceRequests$.getValue());
  }

  private _setTheme(theme: string): void {
    document.body.className = `es-theme-${theme.toLowerCase()}`;
  }

  private _checkTimeout(): boolean {
    const now = new Date();
    const threshold = now.setMinutes(now.getMinutes() - TIMEOUT_MINUTES);

    this._logger.debug('[AppService].[checkTimeout] threshold:', threshold);

    if (this._stateService.state.timeoutLastAction < threshold) {
      this._logger.warn('[AppService].[checkTimeout] exceeded');
      this.clearAppData();
      return false;
    }

    this._stateService.state.timeoutLastAction = new Date().getTime();
    this._stateService.save();

    return true;
  }

  private _checkTimeoutAndRestartIfRequired(): boolean {
    if (!this._checkTimeout()) {
      this.goToStep('branches');
      return false;
    }

    return true;
  }

  private _clearSlots() {
    this._slot$.next(undefined);
    this._stateService.state.slot = undefined;

    this._bookingServiceRequests$.getValue().forEach(service => {
      service.slot = undefined;
    });

    this._stateService.state.bookedServices = this._bookingServiceRequests$.getValue();

    this._logger.debug('[AppService].[clearSlots] bookedServices:', this._stateService.state.bookedServices);
  }

  private _clearStaff() {
    this._bookingServiceRequests$.getValue().forEach(service => {
      service.slot = undefined;
      service.staffRef = undefined;
      service.staffPreferred = false;
    });

    this._stateService.state.bookedServices = this._bookingServiceRequests$.getValue();

    this._logger.debug('[AppService].[clearStaff] bookedServices:', this._stateService.state.bookedServices);
  }

  public addService(serviceOrPackage: IService, staff?: IStaff) {
    this._logger.debug('[AppService].[addService] service:', serviceOrPackage);
    this._logger.debug('[AppService].[addService] staff:', staff);

    if (!this._checkTimeoutAndRestartIfRequired()) { return; }

    const selectedServicesAndPackages = this._selectedServicesAndPackages$.getValue();
    selectedServicesAndPackages.push(serviceOrPackage);
    this._publishSelectedServices(selectedServicesAndPackages);

    const bookingServiceRequests = this._bookingServiceRequests$.getValue();

    if (serviceOrPackage.isPackage) {
      serviceOrPackage.packageItemRefs.forEach(itemRef => {
        bookingServiceRequests.push({
          itemRef: itemRef,
          packageRef: serviceOrPackage.id,
          staffPreferred: !!staff,
          staffRef: (!!staff)? staff.id : undefined
        });
      });
    } else {
      bookingServiceRequests.push({
        itemRef: serviceOrPackage.id,
        staffPreferred: !!staff,
        staffRef: (!!staff)? staff.id : undefined
      });
    }

    this._publishBookedServices(bookingServiceRequests);
  }

  public busy(busy: boolean) {
    this._logger.trace('AppService | busy', busy);
    this._busy$.next(busy);
  }

  /**
   * Delete all data in local storage, including user authentication data.
   */
  public clearAppData() {
    this._logger.debug('[AppService].[clearAppData]');
    this._stateService.reset();
  }

  public clearError() {
    this._logger.trace('AppService | clearError');
    this._error$.next(null);
  }

  public getBookingDurationMinutes(): number {
    return this._selectedServicesAndPackages$.getValue()
      .map(service => service.bookingDurationMinutes + service.bookingGapMinutes)
      .reduce((total, current) => total + current, 0);
  }

  public getSelectedSlot(): IBookingSlot | undefined {
    return this._slot$.getValue();
  }

  public goBack() {
    let backStep: string;

    const step = this._step$.getValue();
    switch (step) {
      case 'detailsUpdate':
        this._router.navigateByUrl(`/${this._subscriptionSlug$.getValue()}/details`);
        return;

      case 'services':
        this._stateService.state.selectedServices = [];
        this._selectedServicesAndPackages$.next([]);
        backStep = 'branches';
        break;

      case 'staff':
        this._clearStaff();
        backStep = 'services';
        break;

      case 'dates':
        this._clearSlots();
        backStep = 'staff';
        break;

      case 'user-details':
        backStep = 'dates';
        break;

      case 'confirmed':
        backStep = 'user-details';
        break;

      default:
        this._logger.error('unknown back step', step);
        break;
    }

    this._stateService.save();
    this.goToStep(backStep!);
  }

  public goToNextStep() {
    const step = this._step$.getValue();

    switch (step) {
      case 'subscription':
        return this.goToStep('branches');

      case 'branches':
        return this.goToStep('services');

      case 'services':
        return this.goToStep('staff');

      case 'staff':
        return this.goToStep('dates');

      case 'dates':
        return this.goToStep('user-details');

      case 'user-details':
        return this.goToStep('confirmed');

      default:
        this._logger.error('unknown next step', step);
        break;
    }
  }

  public goToNextUncompletedStep() {
    this._logger.trace('[AppService].[goToNextUncompletedStep]');

    if (!this._branch$.getValue()) {
      return this.goToStep('branches');
    }

    if (!this._selectedServicesAndPackages$.getValue().length) {
      return this.goToStep('services')
    }

    if (!this._bookingServiceRequests$.getValue().some(s => !s.staffRef || s.staffRef < 1)) {
      return this.goToStep('staff')
    }

    if (!this._slot$.getValue()) {
      return this.goToStep('dates');
    }

    if (!this._stateService.state.isConfirmed) {
      return this.goToStep('user-details');
    }

    return this.goToStep('confirmed');
  }

  public goToStep(step: string) {
    this._logger.trace('[AppService].[goToStep]', step);

    if (!this._checkTimeout()) {
      step = 'branches';
    }

    switch (step) {
      case 'branches':
        return this._navigateTo('branches');

      case 'services':
        return this._navigateTo('services');

      case 'staff':
        return this._navigateTo('staff');

      case 'dates':
        return this._navigateTo('dates');

      case 'user-details':
        return this._navigateTo('details');

      case 'confirmed':
        return this._navigateTo('confirmed');

      default:
        this._logger.error('unknown step', step);
        break;
    }
  }

  public hasServices(): boolean {
    if (!this._stateService.state.selectedServices.length) { return false; }
    return true;
  }

  public hasSlot(): boolean {
    return !!this._stateService.state.slot;
  }

  public hasStaffPreference(): boolean {
    throw new Error('hasStaffPreference');
    // return this._stateService.state.staffPreferred;
  }

  public logout() {
    this._authService.logout();
    this.clearAppData();
    this._router.navigateByUrl(`/${this._subscriptionSlug$.getValue()}/auth/login`);
  }

  public needNewSlot() {
    this.setError({
      title: 'Your selected day and time is no longer available. Please select a new day and time.'
    });

    this.setSlot(undefined);

    this.goToStep('dates');
  }

  public rebook(existingBooking: IBooking)  {
    this._logger.trace('AppService | Rebook', existingBooking.headBookingRef);
    this.busy(true);
    this.clearError();

    const branch = this._branchService.findByRef(existingBooking.branchRef);
    if (!branch) {
      this.setError({title: 'This branch is no longer available.'});
      this.busy(false);
      return;
    }

    this.setBranch(branch)
      .subscribe(_ => {
        this.removeAllServices();

        existingBooking.services.forEach(bookedService => {
          const service = this._serviceService.findByRef(bookedService.itemRef);
          if (!service) {
            this.setError({title: `${bookedService.itemName} is no longer available.`});
            this.busy(false);
            return;
          }

          const staff = this._staffService.findByRef(bookedService.staffRef);
          if (!staff) {
            this.setError({title: `${bookedService.staffName} is no longer available.`});
            this.busy(false);
            return;
          }

          this.addService(service, staff);
        });

        this.goToStep('dates');
        this.busy(false);
      }, error => {
        this._logger.error('AppService | Rebook | setBranch', error);
        this.setError(error);
        this.busy(false);
      });
  }

  public removeAllServices() {
    this._logger.debug('[AppService].[removeAllServices]');

    this._publishSelectedServices([]);
    this._publishBookedServices([]);
  }

  public removeService(service: IService) {
    this._logger.debug('[AppService].[removeService]', service);

    if (!this._checkTimeoutAndRestartIfRequired()) { return; }

    const selectedServices = this._selectedServicesAndPackages$.getValue().filter(s => s.id !== service.id);
    this._publishSelectedServices(selectedServices);

    let bookedServices = this._bookingServiceRequests$.getValue();

    if (service.isPackage){
      // We want to remove all items which are linked to this package
      bookedServices = bookedServices.filter(s => s.packageRef !== service.id);
    } else {
      // We want to remove the specific item, as long as it's not part of a package
      bookedServices = bookedServices.filter(s => s.itemRef !== service.id || !!s.packageRef);
    }

    this._publishBookedServices(bookedServices);
  }

  public setBranch(branch: IBranch): Observable<null> {
    this._logger.trace('AppService | setBranch', branch);

    if (!this._checkTimeoutAndRestartIfRequired()) { return of(null) }

    this._stateService.state.branchRef = branch.id;
    this._stateService.save();
    this._branch$.next(branch);

    return this._loadBranchData();
  }

  public setError(error: any) {
    this._logger.error('AppService | setError', error);

    this._error$.next(error);
  }

  public setSlot(slot: IBookingSlot | undefined) {
    if (!this._checkTimeoutAndRestartIfRequired()) { return; }

    this._logger.trace('[AppService].[setSlot]', slot);

    this._slot$.next(slot);

    this._stateService.state.slot = slot;
    this._stateService.save();
  }

  public setStep(step: string) {
    setTimeout(() => {
      this._logger.trace('AppService | setStep', step);
      this._step$.next(step);

      switch (step) {
        case 'appointments':
        case 'details':
        case 'login':
        case 'loginCode':
          this._canGoBack$.next(false);
          this._showSidebar$.next(false);
          break;

        case 'detailsUpdate':
          this._canGoBack$.next(true);
          this._showSidebar$.next(false);
          break;

        case 'branches':
          this._canGoBack$.next(false);
          this._showSidebar$.next(true);
          break;

        case 'confirmed':
          const business = this._business$.getValue();
          this._titleService.setTitle(`Booking confirmed - ${business?.tradingName}`);
          this._canGoBack$.next(false);
          this._showSidebar$.next(true);
          break;

        case 'dates':
        case 'services':
        case 'staff':
        case 'user-details':
          this._canGoBack$.next(true);
          this._showSidebar$.next(true);
          break;

        default:
          this._logger.error('unknown step', step);
          break;
      }
    });
  }

  public setStaff(): Observable<null> {
    if (!this._checkTimeoutAndRestartIfRequired()) { return of(null); }

    this._setStaff();
    return this._loadSlot();
  }

  public setSubscription(subscription: ISubscription, subscriptionSlug: string): Observable<null> {
    this._logger.trace('[AppService].[setSubscription]', subscription);

    // Load the state first
    this._stateService.load(subscriptionSlug);

    // Check the timeout, this will reset the state if required
    this._checkTimeout();

    // The trigger the observables, since some subscribers needs the state to be loaded first
    this._subscription$.next(subscription);
    this._subscriptionSlug$.next(subscriptionSlug);

    return this._loadBusiness();
  }

  public setCustomer(customer: ICustomer) {
    this._logger.trace('AppService | setCustomer', customer);

    const userDetails: ICustomerDetails = {
      comments: '',
      customerRef: customer.customerRef,
      email: customer.emailAddress,
      firstName: customer.preferredName.length ? customer.preferredName : customer.firstName,
      lastName: customer.lastName,
      mobile: customer.mobileNumber,
      subscribe: customer.emailPromotions,
      acceptTerms: false
    };

    this.setUserDetails(userDetails);
  }

  public setUserDetails(details: ICustomerDetails) {
    this._logger.trace('AppService | setUserDetails', details);
    this._userDetails$.next(details);

    this._stateService.state.userDetails = details;
    this._stateService.save();
  }

  public startOver() {
    this._logger.debug('[AppService].[startOver]');

    this.clearAppData();
    this._actualServices$.next([]);
    this._bookingServiceRequests$.next([]);
    this._selectedServicesAndPackages$.next([]);
    this._slot$.next(undefined);
    this._slotEnds$.next(null);

    this.goToNextUncompletedStep();
  }

  public submitBooking(): Observable<IBookingResponse> {
    return this._recaptcha.execute('submit_booking')
      .pipe(
        mergeMap(token => {
          // We might already have a booking with a payment issue, resubmit that
          if (this._stateService.state.bookingRef) {
            return this._bookingsService.resubmitBooking(this._stateService.state.bookingRef!, token);
          }

          const request: IBookingRequest = {
            branchRef: this._stateService.state.branchRef!,
            customerRef: this._stateService.state.userDetails!.customerRef,
            email: this._stateService.state.userDetails!.email,
            firstName: this._stateService.state.userDetails!.firstName,
            lastName: this._stateService.state.userDetails!.lastName,
            mobile: this._stateService.state.userDetails!.mobile,
            notes: this._stateService.state.userDetails!.comments,
            recaptchaToken: token,
            services: this._stateService.state.slot!.services,
            startDateTime: this._stateService.state.slot!.startTime,
            subscribe: this._stateService.state.userDetails!.subscribe
          };

          this._logger.debug('[AppService].[submitBooking] services:', this._stateService.state.slot!.services);
          this._logger.debug('[AppService].[submitBooking] request:', request);

          return this._bookingsService.submitBooking(request);
        }),
        tap(response => {
          this._logger.debug('[AppService].[submitBooking] response:', response);
          this._stateService.state.bookingRef = response.bookingId;
          this._stateService.save();

          if (!!response.paymentUrl) {
            window.location.href = response.paymentUrl;
          } else {
            this._stateService.state.isConfirmed = true;
            this._stateService.save();
          }
        })
      );
  }

}
