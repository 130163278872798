import { Injectable } from '@angular/core';
import { IAppState } from '../app.state';
import { v4 as uuidv4 } from 'uuid';

const LS_LAST_SUBSCRIPTION = 'esalon.lastSubscription';

const defaultState: IAppState = {
  bookedServices: [],
  isConfirmed: false,
  selectedServices: [],
  timeoutLastAction: new Date().getTime(),
  uniqueId: uuidv4(),
  userDetails: null
};

@Injectable({ providedIn: 'root' })
export class StateService {

  private _isLoaded = false;

  public state: IAppState = defaultState;

  private _getStateKey(subscriptionSlug: string): string {
    return `esalon.${subscriptionSlug}.state`;
  }

  public load(subscriptionSlug: string): IAppState {
    const key = this._getStateKey(subscriptionSlug);
    const storedStateData = localStorage.getItem(key);
    let storedState = {};

    if (!!storedStateData) {
      storedState = JSON.parse(storedStateData);
    }

    this.state = Object.assign({}, defaultState, storedState);

    this.state.subscriptionSlug = subscriptionSlug;
    this.save();

    localStorage.setItem(LS_LAST_SUBSCRIPTION, subscriptionSlug);

    this._isLoaded = true;

    return this.state;
  }

  public loadLastSubscription() {
    if (this._isLoaded) { return; }

    const lastSubscription = localStorage.getItem(LS_LAST_SUBSCRIPTION);
    if (!lastSubscription) {
      return;
    }

    this.load(lastSubscription);
  }

  public reset() {
    const dataToKeep = {
      branchRef: this.state.branchRef,
      subscriptionSlug: this.state.subscriptionSlug,
      uniqueId: this.state.uniqueId
    }

    this.state = Object.assign({}, defaultState, dataToKeep);

    this.save();
  }

  public save() {
    if (!this.state?.subscriptionSlug) {
      return;
    }

    const key = this._getStateKey(this.state.subscriptionSlug);
    localStorage.setItem(key, JSON.stringify(this.state));
  }

}
