import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SpinnerComponent } from 'src/app/components/spinner/spinner.component';
import { LoginCodeComponent } from './components/login/code/login-code.component';
import { LoginComponent } from './components/login/login.component';

export * from './models/index';
export * from './services/auth.service';

@NgModule({
  declarations: [
    LoginComponent,
    LoginCodeComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SpinnerComponent,
    RouterModule.forChild([
      { path: 'login', component: LoginComponent },
      { path: 'login/code', component: LoginCodeComponent },
    ])
  ],
  exports: [
    RouterModule
  ]
})
export class AuthModule { }
