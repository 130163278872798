import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  transform(minutes: number): string {
    const m = minutes % 60;
    const h = (minutes - m) / 60;

    if (h === 0) { return `${minutes} mins`; }
    if (h === 1 && m === 0) { return '1 hour'; }
    if (h === 1) { return `1 hour, ${m} mins`; }
    if (m === 0) { return `${h} hours`; }
    return `${h} hours, ${m} mins`;
  }

}
